import { useShoppingCartStore } from "@multicines/stores";
import { CinemaStores, City } from "@multicines/types";
import { titleCase } from "@multicines/utils";
import { getShoppingCartProducts } from "artisn/shopping-cart";
import { Button, Modal } from "artisn-ui-react";
import ArrowToDown from "images/arrow-left-small.svg";
import CloseSVG from "images/close.svg";
import React, { useState } from "react";

import Styles, { ModalCinema } from "./CinemaAndDay.styles";
import { CinemaAndDayProps as Props } from "./CinemaAndDay.types";
import ChangeStoreModal from "components/global/ChangeStoreModal/ChangeStoreModal";
import SelectCinema from "components/global/SelectCinema/SelectCinema";
import SelectDate from "components/global/SelectDate/SelectDate";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";

const CinemaAndDay: React.FC<Props> = props => {
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const { isTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isTalkShop
  });
  const t = useI18n();
  const selectedStoreContext = useStoresStore(store => store.selectedStore);
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);

  const { logSelectLocationCinema } = analyticsHook;

  const [ticketsStore] = selectedStoreContext ?? [];
  const { storeName } = ticketsStore ?? {};

  const products = shoppingCart
    ? getShoppingCartProducts(shoppingCart)
    : undefined;
  const empty = (products && products.length === 0) || !shoppingCart;

  const [showModal, setShowModal] = useState(false);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<City | undefined>();
  const [selectedStore, setSelectedStore] = useState<
    CinemaStores | undefined
  >();

  const changeModalHandler = () => {
    logSelectLocationCinema();

    if (empty) {
      setShowModal(true);
      setShowChangeModal(false);
      return;
    }

    setShowChangeModal(false);
    setShowModal(true);
  };

  const handleChangeCinema = (newCity: City, newStore: CinemaStores) => {
    setSelectedCity(newCity);
    setSelectedStore(newStore);
    setShowModal(false);
    setShowChangeModal(true);
  };

  return (
    <Styles className="CinemaAndDay" isAnonymous={isAnonymous}>
      <Button className="CinemaAndDay__store" onClick={changeModalHandler}>
        <p className="CinemaAndDay__top__text" suppressHydrationWarning>
          {storeName ? titleCase(storeName) : t.navigation.Navbar.selectCinema}
        </p>
        <ArrowToDown className="CinemaAndDay__top__icon" />
      </Button>
      <ModalCinema
        color="hsla(223, 13%, 11%, 1)"
        closeIcon={<CloseSVG />}
        opened={showModal}
        onClose={() => setShowModal(false)}
        backdropConfig={{ className: "modalBackdrop modalUp" }}
      >
        <SelectCinema
          onChangeCinema={handleChangeCinema}
          setSelectShowModal={setShowModal}
          setShowModal={setShowModal}
        />
      </ModalCinema>
      {selectedCity && selectedStore ? (
        <Modal
          color="transparent"
          closeIcon={<CloseSVG />}
          opened={showChangeModal}
          onClose={() => setShowChangeModal(false)}
        >
          <ChangeStoreModal
            setShowSelectModal={setShowModal}
            setShowChangeModal={setShowChangeModal}
            newCity={selectedCity}
            newStore={selectedStore}
          />
        </Modal>
      ) : null}
      <p className="CinemaAndDay__top__separator">|</p>
      <SelectDate />
    </Styles>
  );
};

CinemaAndDay.defaultProps = {};

export default CinemaAndDay;
